import "../scss/application.scss";
import * as bootstrap from "bootstrap";
require.context("../images", true);
import { egeCalculator } from "./ege_calc";
import { initYandexMetrica } from "./yandex_metrica";

// Условия поступления
document.addEventListener("DOMContentLoaded", () => {
  const tabs = document.querySelectorAll('.wrapper-tabs .custom-tab');
  const contents = document.querySelectorAll('.custom_tab_item');

  tabs.forEach((tab, index) => {
    tab.onclick = () => {
      tabs.forEach(t => t.classList.remove('active'));
      contents.forEach(c => c.style.display = 'none');

      tab.classList.add('active');
      contents[index].style.display = 'block';

      Object.assign(contents[index].style, {
        opacity: 0,
        transition: 'opacity 0.5s'
      });

      setTimeout(() => contents[index].style.opacity = 1, 10);
    };
  });
});

document.addEventListener("DOMContentLoaded", function () {
  let lastId = undefined;
  const rightMenu = document.querySelector(".un_nav_box ul");
  const rightMenuHeight = rightMenu.offsetHeight;
  const menuItems = Array.from(rightMenu.querySelectorAll("a"));
  const scrollItems = menuItems.map(menuItem => document.querySelector(menuItem.getAttribute("href"))).filter(item => item);

  // получаем самый нижний активный элемент из правого меню
  const getActiveId = () => {
    let fromTop = window.scrollY + rightMenuHeight;
    const activeItem = scrollItems.findLast(item => item.offsetTop < fromTop);
    return activeItem?.id;
  };

  // Добавляем обработчик события прокрутки для обновления активного элемента правого меню.
  window.addEventListener("scroll", () => {
    const currentId = getActiveId();

    if (lastId !== currentId) {
      menuItems.forEach(a => a.parentNode.classList.toggle("active", a.hash === `#${currentId}`));
      lastId = currentId;
    }
  });
});

document.addEventListener("DOMContentLoaded", function () {
  egeCalculator();
});


document.addEventListener("DOMContentLoaded", () => {
  const phoneModal = document.getElementById('phone-modal');
  const loadingEl = phoneModal.querySelector('#phone-loading');
  const successEl = phoneModal.querySelector('#phone-success');
  const errorEl = phoneModal.querySelector('#phone-error');
  const phoneParentEl = phoneModal.querySelector('#phone-form');
  phoneModal.addEventListener('show.bs.modal', function () {
    phoneModal.querySelector('input').focus()
    loadingEl.classList.add('d-none');
    successEl.classList.add('d-none');
    errorEl.classList.add('d-none');
    phoneParentEl.classList.remove('d-none');
  })

  const form = phoneModal.querySelector('.phone_form')
  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    loadingEl.classList.remove('d-none');
    let response = await fetch('/phones', {
      method: 'POST',
      body: new FormData(event.target)
    });
    loadingEl.classList.add('d-none');

    const status = response.status;
    if (status === 200) {
      phoneParentEl.classList.add('d-none');
      errorEl.classList.add('d-none');
      successEl.classList.remove('d-none');
    } else {
      errorEl.classList.remove('d-none');
      successEl.classList.add('d-none');
    }
  })
})


document.addEventListener("DOMContentLoaded", () => {
  initYandexMetrica()
})