import * as bootstrap from 'bootstrap';

export function egeCalculator() {
  // Чтобы понять про происходит выведи в консоль data
  // const data = [
  // {
  // disciplinesFree: [ массив со списком диспиплин по направлению, 
  //   {
  //     disciplines: [string, string, ...], массив с именами дисциплин. массив а не просто string, 
  //         т.к. может быть Математика (проф.) или Химия на одной строке в таблице
  //     scores: [number, number, ..], массив с балами
  //     compare: boolean выбрана ли хоть одна из дисциплин из списка disciplines: []
  //   },
  // ],
  // disciplinesPaid: [тоже что и фри],
  // htmlNode: html элемент detail,
  // name: Название направления
  // }
  // ]
  const [data, disciplinesByType] = parseHtml();
  // Добавляем checkbox`сы на страницу
  const parent = document.querySelector('.ege-calculator');
  addCheckboxes(parent, disciplinesByType);

  const myModal = new bootstrap.Modal(document.getElementById('get-brochure-popup5'));
  const checkboxes = document.querySelectorAll('.ege-checkbox');
  const errorText = document.querySelector('.ege-error-text');

  const btn = document.querySelector('.ege-calc-submit');
  btn.addEventListener('click', function (e) {
    e.preventDefault();
    const selectedDisciplines = checkCheckedCheckbox(checkboxes);
    // Показать все, если не выбрано не одна специальность. т.е. тупо закрыть окно
    if (selectedDisciplines.length === 0) {
      removeClassByHtmlSelector('.faculty', 'd-none');
      removeClassByHtmlSelector('.faculty-details', 'd-none');
      errorText.classList.add('d-none');
      myModal.hide();
    } else {
      // Если выброно >= 1 и меньше 3 специальностей
      if (selectedDisciplines.length < 3) {
        errorText.classList.remove('d-none');
      } else {
        showSpecialities(selectedDisciplines);
        errorText.classList.add('d-none');
        myModal.hide();
      }
    }
  })

  function showSpecialities(selectedDisciplines) {
    //reset 
    data.forEach((e) => {
      e.disciplinesFree.forEach((t) => {
        t.compare = false;
      })
      e.disciplinesPaid.forEach((t) => {
        t.compare = false;
      })
    })
    //end rest
    selectedDisciplines.forEach(function (discipline) {
      data.forEach(function (e) {
        e.disciplinesFree.forEach((t) => compareDiscipline(t, discipline));
        e.disciplinesPaid.forEach((t) => compareDiscipline(t, discipline));
      })
    })

    //hide all specialities
    addClassByHtmlSelector('.faculty-details', 'd-none');

    let tmp = data.filter(t => t.disciplinesFree.filter(v => v.compare).length === t.disciplinesFree.length || t.disciplinesPaid.filter(v => v.compare).length === t.disciplinesPaid.length);
    let foundType = 0; // nothing found
    if (tmp.length === 0) {
      tmp = data.filter(t => t.disciplinesFree.filter(v => v.compare).length === t.disciplinesFree.length - 1 || t.disciplinesPaid.filter(v => v.compare).length === t.disciplinesPaid.length - 1);
      if (tmp.length !== 0) {
        foundType = 2; // найденно по 2 предметам
      }
    } else {
      foundType = 1; // найденно полное соответствие
    }
    switch (foundType) {
      case 0:
        document.querySelector('.ege_seme_found_text').classList.add('d-none');
        document.querySelector('.ege_not_found_text').classList.remove('d-none');
        break;
      case 1:
        document.querySelector('.ege_seme_found_text').classList.add('d-none');
        document.querySelector('.ege_not_found_text').classList.add('d-none');
        break;
      default:
        document.querySelector('.ege_seme_found_text').classList.remove('d-none');
        document.querySelector('.ege_not_found_text').classList.add('d-none');
        break;
    }
    tmp.forEach(function (e) {
      e.htmlNode.classList.remove('d-none');
    })

    document.querySelectorAll('.faculty').forEach(function (e) {
      const selectedDetailHtmlElements = [...e.querySelectorAll('.faculty-details')].filter(t => !t.classList.contains('d-none'));
      if (selectedDetailHtmlElements.length === 0) {
        e.classList.add('d-none')
      } else {
        e.classList.remove('d-none')
      }
    })
  }

}

function removeClassByHtmlSelector(htmlSelector, className) {
  document.querySelectorAll(htmlSelector).forEach(function (e) {
    e.classList.remove(className);
  })
}

function addClassByHtmlSelector(htmlSelector, className) {
  document.querySelectorAll(htmlSelector).forEach(function (e) {
    e.classList.add(className);
  })
}

function parseHtml() {
  const rawSpecialities = document.querySelectorAll('.faculty-details');
  const data = [];
  const allRawDisciplines = [];
  rawSpecialities.forEach(function (rawSpeciality, index) {
    const result = {
      htmlNode: rawSpeciality,
      name: rawSpeciality.querySelector('summary').innerHTML.trim(),
      disciplinesFree: [],
      disciplinesPaid: [],
    }
    let free = false;
    let paid = false;
    const tables = rawSpeciality.querySelectorAll('table');
    for (let table of tables) {
      table.querySelector('tbody').querySelectorAll('tr').forEach(function (tr) {
        const tmp = tr.querySelectorAll('td');
        const text = tr.closest('table').previousElementSibling.innerHTML;

        if (text === 'На бюджетной и платной основе') {
          free = true;
          paid = true;
        }
        if (text === 'На бюджетной основе') {
          free = true;
          paid = false;
        }
        if (text === 'На платной основе') {
          free = false;
          paid = true;
        }

        const disciplines = tmp[0].innerHTML.trim().split(' или ');
        const scores = tmp[1].innerHTML.trim().split('/');
        disciplines.forEach(function (discipline) {
          allRawDisciplines.push(discipline);
        })
        if (free) {
          result.disciplinesFree.push({
            disciplines: disciplines,
            scores: scores.map(t => parseInt(t, 10)),
            compare: false
          })
        }
        if (paid) {
          result.disciplinesPaid.push({
            disciplines: disciplines,
            scores: scores.map(t => parseInt(t, 10)),
            compare: false
          })
        }
      })
    }
    data.push(result)
  })
  return [data, groupDisciplinesForFilter(allRawDisciplines)]
}

// Группируем дисциплины, группы
// Русский Математика
// Другие предметы
// Всякие испытания
function groupDisciplinesForFilter(allRawDisciplines) {
  const disciplines = [...new Set(allRawDisciplines)];
  const primaryDisciplines = disciplines.filter(t => t.match(/^Математика/) || t.match(/^Русский язык/)).sort();
  const tmpDisciplines = disciplines.filter(t => !t.match(/^Математика/) && !t.match(/^Русский язык/));
  const secondaryDisciplines = tmpDisciplines.filter(t => !t.includes('испытание')).sort();
  const additionalDisciplines = tmpDisciplines.filter(t => t.includes('испытание')).sort();
  return [primaryDisciplines, secondaryDisciplines, additionalDisciplines];
}

// Добавляем checkbox`сы на страницу
function addCheckboxes(parent, disciplinesByType) {
  disciplinesByType.forEach((disciplines, index) => {
    disciplines.forEach((discipline, index2) => {
      parent.appendChild(createCheckBox(discipline, "checkboxId_" + index + "_" + index2))
    })
    // После последнего блока hr не добавляем
    if (index + 1 !== disciplinesByType.length) {
      parent.appendChild(document.createElement('hr'));
    }
  })
}

function createCheckBox(name, checkboxId) {
  const checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.name = name;
  checkbox.id = checkboxId;
  checkbox.classList.add('ege-checkbox');
  // При нажатие на checkbox делаем видимым input с баллами
  checkbox.addEventListener('change', function (e) {
    e.target.parentElement.parentElement.querySelector('input[type="number"]').classList.toggle('d-none');
  })

  const label = document.createElement('label');
  label.textContent = name;
  label.classList.add('ms-1');
  label.setAttribute('for', checkboxId);

  const checkboxContainer = document.createElement('div');
  checkboxContainer.classList.add('d-flex');
  checkboxContainer.classList.add('align-items-center');
  checkboxContainer.style.minHeight = "38px";
  checkboxContainer.appendChild(checkbox);
  checkboxContainer.appendChild(label);

  const score = document.createElement('input');
  score.type = 'number';
  score.value = 100;
  score.classList.add('d-none');
  score.classList.add('form-control');
  // Проверяем что балов не может быть больше 100, меньше 0, и убираем дроби
  score.addEventListener('change', function (e) {
    const number = parseInt(e.target.value);
    e.target.value = number;
    if (number > 100) {
      e.target.value = 100;
    }
    if (number < 0) {
      e.target.value = 0;
    }
  })

  const container = document.createElement('div');
  container.classList.add('mb-2');
  container.appendChild(checkboxContainer);
  container.appendChild(score);
  return container;
}

function checkCheckedCheckbox(checkboxes) {
  const selectedDisciplines = [];
  checkboxes.forEach(function (checkbox) {
    if (!checkbox.checked) {
      return;
    }
    const score = checkbox.parentElement.parentElement.querySelector('input[type="number"]').value;
    selectedDisciplines.push({
      name: checkbox.name,
      score: parseInt(score)
    })
  })
  return selectedDisciplines;
}

function compareDiscipline(data, discipline) {
  for (let i = 0; i < data.disciplines.length; i++) {
    if (discipline.name === data.disciplines[i] && discipline.score >= data.scores[i]) {
      data.compare = true;
    }
  }
}