function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Функция для установки кросс-доменного cookie
function setCrossDomainCookie(name, value, domain) {
  // Устанавливаем значения для cookie
  var cookieValue = name + "=" + encodeURIComponent(value) + ";";

  // Добавляем атрибут domain для кросс-доменности
  // cookieValue += "domain=" + domain + ";";

  // Устанавливаем путь cookie
  cookieValue += "path=/;";

  // Добавляем атрибуты secure и SameSite=None для отправки cookie при кросс-доменных запросах
  cookieValue += `secure;SameSite=None;max-age=${60 * 60 * 24 * 365}`;

  // Устанавливаем cookie
  document.cookie = cookieValue;
}

export function initYandexMetrica() {
  const messageElement = document.querySelector('#cookie-notification');
  // Если нет cookies, то показываем плашку
  const agree = getCookie('cookies-agreement')
  if (agree) {
    initCounter();
  } else {
    showMessage();
  }

  // Функция, которая показывает предупреждение
  function showMessage() {
    messageElement.classList.add('cookie-notification__show');
  }

  function initCounter() {
    (function (d, w, c) {
      (w[c] = w[c] || []).push(function () {
        try {
          w.yaCounter88941995 = new Ya.Metrika({
            id: 88941995,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true
          });
        } catch (e) { }
      });

      var n = d.getElementsByTagName("script")[0],
        x = "https://mc.yandex.ru/metrika/watch.js",
        s = d.createElement("script"),
        f = function () { n.parentNode.insertBefore(s, n); };
      for (var i = 0; i < document.scripts.length; i++) {
        if (document.scripts[i].src === x) { return; }
      }
      s.type = "text/javascript";
      s.async = true;
      s.src = x;

      if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
      } else { f(); }
    })(document, window, "yandex_metrika_callbacks");
    enableVk()

    messageElement.style.display = 'none';
    setCrossDomainCookie('cookies-agreement', '1', '.localhost');
  }

  // Нажатие кнопки "Я согласен"
  document.querySelector('#cookie-notification-yes').addEventListener('click', function () {
    initCounter();
  });
}

function enableVk() {
  var vk_script = document.createElement('script');
  vk_script.type = 'text/javascript';
  vk_script.innerHTML = `
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: "3338526", type: "pageView", start: (new Date()).getTime()});
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
      ts.src = "https://top-fwz1.mail.ru/js/code.js";
      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "tmr-code");
  `
  document.head.appendChild(vk_script);
}
